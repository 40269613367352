import axios from 'axios';
import React, { useState, useRef, useEffect} from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCalendarPlus, faFileAlt, faSave } from '@fortawesome/free-solid-svg-icons'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  SendButton,
  Conversation,
  Avatar,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
  MessageSeparator,
  Sidebar,
  Search,
  ConversationList,
  ExpansionPanel,
  Button,
} from "@chatscope/chat-ui-kit-react";
import femenino from "../img/femenino.svg";
import masculino from "../img/masculino.svg";

export default function Test() {
    const inputRef = useRef();
    const [msgInputValue, setMsgInputValue] = useState("");
    const [messages, setMessages] = useState([]);
    const [users, setUsersList] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [activeConversation, setActiveUser] = useState(0);

    const Genero = () => (
        <p>
            Género: 
            <select id="genero" value={users.length > 0 && users[activeConversation].genero !== null ? users[activeConversation].genero : "sin especificar" } onChange={() => CambiarGenero(this)}>
                <option value="masculino">Masculino</option>
                <option value="femenino">Femenino</option>
                <option value="sin especificar">Sin especificar</option>
            </select>
        </p>
    );

    const Datos = () => (
        <p>
            <div>
                <label>
                    Nombre:
                    <input id="nombreSave" type="text" name="name" />
                </label>
                <br/>
                <label>
                    Género: 
                    <select id="generoSave" value={users.length > 0 && users[activeConversation].genero !== null ? users[activeConversation].genero : "sin especificar" }>
                        <option value="masculino">Masculino</option>
                        <option value="femenino">Femenino</option>
                        <option value="sin especificar">Sin especificar</option>
                    </select>
                </label>
                <br/>
                <Button icon={<FontAwesomeIcon icon={faSave} onClick={() => GuardarDatos()} />} border>Guardar datos</Button>
            </div>
        </p>
    );

    const CambiarGenero = async(select) => {
        try {
            const genero = document.getElementById("genero").value;
            
            const response = await axios.get('https://vr.virtualcongress.integrameetings.com/whatsapp.php?update&genero='+genero+'&tel=' + users[activeConversation].number);
            response.data.update.forEach(function chats(item, index, arr) {
                const data = JSON.parse(item);
                if(data){
                    users[activeConversation].genero = genero;
                    users[activeConversation].avatar = genero === "masculino" || genero === "sin especificar" ? masculino : femenino;        
                    setActiveConversation(activeConversation);
                }else{
                    console.error("Algo salió mal");
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    const GuardarDatos = async(select) => {
        try {
            const genero = document.getElementById("generoSave").value;
            const nombre = document.getElementById("nombreSave").value;
            const response = await axios.get('https://vr.virtualcongress.integrameetings.com/whatsapp.php?insert&nombre='+nombre+'&genero='+genero+'&tel=' + users[activeConversation].number);
            response.data.update.forEach(function chats(item, index, arr) {
                const data = JSON.parse(item);
                if(data){
                    users[activeConversation].genero = genero;
                    users[activeConversation].avatar = genero === "masculino" || genero === "sin especificar" ? masculino : femenino;        
                    setActiveConversation(activeConversation);
                }else{
                    console.error("Algo salió mal");
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    const handleSend = async(message) => {
        try {
            var parametros = {};
            if(message === "template"){
                parametros = {
                    to: users[activeConversation].number,
                    template: message
                };
            }else{
                parametros = {
                    to: users[activeConversation].number,
                    msg: message
                };
            }
            await axios.get(
                'https://vr.virtualcongress.integrameetings.com/api.php',
                {
                    params: parametros
                }
            ).then(function (response) {
                setMessages([...messages, {
                    message,
                    direction: 'outgoing',
                    sentTime: "Hace unos momentos",
                    position: 'single'
                }]);
                setMsgInputValue("");
                inputRef.current.focus();
            })
            .catch(function (error) {
                console.error(error);
            });
            
        } catch (error) {
            console.error(error);
        }
    };

    const setActiveConversation = React.useCallback(async (id, number) => { 
        try {
            var tel = "";
            if(number !== undefined){
                tel = number;
            }else{
                tel = users[id].number;
            }
            const response = await axios.get('https://vr.virtualcongress.integrameetings.com/whatsapp.php?chat&tel=' + tel);
            var conversation = [];
            response.data.chat.forEach(function chats(item, index, arr) {
                const data = JSON.parse(item);
                conversation.push({
                    message: data.msj,
                    direction: data.tipo === 'enviado' ? 'outgoing' : 'incoming',
                    sentTime: data.timestamp,
                    position: 'single'
                });
            });
            setMessages(conversation);
            setActiveUser(id);
        } catch (error) {
            console.error(error);
        }        
    }, [users]);

    useEffect(() => {
        const getUsers = async() => {
            try {
                const response = await axios.get('https://vr.virtualcongress.integrameetings.com/whatsapp.php?chats');
                var chatList = [];
                response.data.chats.forEach(function chats(item, index, arr) {
                    const data = JSON.parse(item);
                    chatList.push({
                        id: 1,
                        genero : data.genero,
                        avatar: data.genero === "masculino" || data.genero === null || data.genero === "sin especificar" ? masculino : femenino,
                        name: data.nombre,
                        lastname: data.apellido === null ? "" : data.apellido,
                        number: data.telefono,
                        lastSenderName: "Bot",
                        info: "...",
                        status: "available",
                        sendMsg: false
                    });
                });
                setUsersList(chatList);
                setActiveConversation(0, chatList[0].number);
            } catch (error) {
                console.error(error);
            }
        }
        if (isInitialRender) {
            setIsInitialRender(false);
            getUsers();
        }
    }, [isInitialRender,setActiveConversation]);

    return (
        <div style={{ position: "relative", height: "100vh" }}>
            <MainContainer responsive>                                   
                <Sidebar position="left" scrollable={false}>
                  <Search placeholder="Buscar..." disabled/>
                  <ConversationList>
                    {
                        users.map((data, i) =>
                            <Conversation key={i} id={data.id} name={data.name === null ? data.number : data.name } lastSenderName={data.lastSenderName} info={data.info} onClick={() => setActiveConversation(i)} active={activeConversation === i}>
                                <Avatar src={data.avatar} name={data.name} status={data.status} />
                            </Conversation>
                        )
                    }                                                      
                  </ConversationList>
                </Sidebar>
                <ChatContainer>
                    <ConversationHeader>
                        <Avatar src={users.length > 0 && (users[activeConversation].genero === "masculino" || users[activeConversation].genero === null || users[activeConversation].genero === "sin especificar" ) ? masculino : femenino } name={users.length > 0 ? users[activeConversation].name : "" } />
                        <ConversationHeader.Content userName={users.length > 0 ? users[activeConversation].name === null ? users[activeConversation].number : users[activeConversation].name : "Por favor seleccione un chat" } info={users.length > 0 && users[activeConversation].sendMsg ? "Se pueden enviar mensajes" : "No hay autorización para enviar mensajes" }/>
                        <ConversationHeader.Actions>
                            <VoiceCallButton />
                            <VideoCallButton />
                            <InfoButton />
                        </ConversationHeader.Actions>          
                    </ConversationHeader>
                    <MessageList>
                        {/*<MessageSeparator content="Saturday, 30 November 2019" />*/}
                        {
                            messages.map((m, i) => 
                                <Message key={i} model={m}>
                                    <Message.Footer sender={m.sender} sentTime={m.sentTime}/>
                                </Message>
                            )
                        }
                    </MessageList>
                    <div as={MessageInput} style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "1px dashed #d1dbe4"
                    }}>
                        <MessageInput 
                            ref={inputRef} 
                            onChange={msg => setMsgInputValue(msg)} 
                            value={msgInputValue} 
                            sendButton={false} 
                            attachButton={false} 
                            onSend={handleSend} 
                            style={{
                                flexGrow: 1,
                                borderTop: 0,
                                flexShrink: "initial"
                            }}
                        />
                        <SendButton onClick={() => handleSend(msgInputValue)} disabled={msgInputValue.length === 0} style={{
                            fontSize: "1.2em",
                            marginLeft: 0,
                            paddingLeft: "0.2em",
                            paddingRight: "0.2em"
                        }}
                        /> 
                    </div>
                </ChatContainer>
                <Sidebar position="right">
                    <ExpansionPanel open title="INFORMACIÓN">
                        <p>{users.length > 0 ? ((users[activeConversation].name === null ? "No hay datos registrados" : users[activeConversation].name) + " " + (users[activeConversation].lastname === null ? "" : users[activeConversation].lastname)) : "No hay información diponible" }</p>
                        { users.length > 0 && users[activeConversation].genero !== null ? <Genero /> : <Datos /> }
                    </ExpansionPanel>
                    <ExpansionPanel title="EVENTOS">
                        <Button icon={<FontAwesomeIcon icon={faCalendarPlus} />} border>Cumbre Latinoamericana del Café</Button>
                    </ExpansionPanel>
                    <ExpansionPanel title="PLANTILLAS">
                        <Button icon={<FontAwesomeIcon icon={faFileAlt} />} border>recordatorio_pago_cumbre</Button>
                        <Button icon={<FontAwesomeIcon icon={faFileAlt} />} border>tipo_soporte</Button>
                    </ExpansionPanel>
                    <ExpansionPanel title="OPCIONES">
                        <Button icon={<FontAwesomeIcon icon={faTrash} />} border>Eliminar conversación</Button>
                    </ExpansionPanel>
                  </Sidebar>
            </MainContainer>
        </div>
    );
};